import React from 'react';
import { useAdminClaim } from '../services/admin';
import { useCurrentUser } from '../services/currentUser';
import { onlyOnClientSide } from './ClientSidePage';

export const ProtectedAdminPage: React.FC = ({ children }) => {
  const { user } = useCurrentUser();
  const [isAdmin, loading] = useAdminClaim(user);
  if (loading) {
    return null;
  }
  if (!isAdmin) {
    return <div>You're not an admin!</div>;
  }

  return <>{children}</>;
};

export const onlyForAdmins = (Component: React.ComponentType) => {
  return onlyOnClientSide(() => (
    <ProtectedAdminPage>
      <Component />
    </ProtectedAdminPage>
  ));
};
