import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { Edit, Trash2, User as UserIcon } from 'react-feather';
import { Menu } from '../../components/AdminMenu';
import { Event } from '../../components/Event';
import { ProductHuntBanner } from '../../components/ProductHuntBanner';
import { onlyForAdmins } from '../../components/ProtectedAdminPage';
import { SEO } from '../../components/SEO';
import { Subheading } from '../../components/Subheading';
import { UserAutocomplete } from '../../components/UserAutocomplete';
import Layout, { Wrapper } from '../../layouts/Layout';
import { useUserMapByIds } from '../../services/admin-users';
import { removeDoc } from '../../services/db';
import { Doc } from '../../services/document';
import { EMPTY_ARR } from '../../services/emptyConstants';
import {
  rsvpForEvent,
  unRsvpForEvent,
  useRsvpsForEvent
} from '../../services/eventRsvps';
import { createEvent, updateEvent, useEvents } from '../../services/events';
import { fromDate, now } from '../../services/time';
import { toUserName } from '../../services/usersPublic';
import styled from '../../styled';
import { IEvent } from '../../types/Event';
import { User } from '../../types/User';

const ControlButton = styled(Button)`
  color: white;

  svg {
    margin-right: ${(p) => p.theme.spacing(1)}px;
  }
`;

const AlignActions = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Form = styled('form')`
  max-width: 500px;
  margin: 0 auto;
`;

const EventWrapper = styled('div')`
  margin: ${(p) => p.theme.spacing(2)}px auto ${(p) => p.theme.spacing(4)}px;
`;

const AttendeeWrapper = styled('ul')`
  list-style-type: none;
  margin: 0 0 ${(p) => p.theme.spacing(2)}px;
  padding: 0;
`;

const AttendeeLi = styled('li')`
  margin: ${(p) => p.theme.spacing(2)}px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: inline-block;
    margin-left: ${(p) => p.theme.spacing(2)}px;
  }

  svg {
    color: white;
    float: right;
  }
`;

const AttendeeRow = ({
  user,
  onDelete
}: {
  user?: User;
  onDelete: () => void;
}) => {
  if (!user) {
    return null;
  }

  const userEmail = user.email;

  return (
    <AttendeeLi>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar src={user.avatarUrl} />
        <span>
          {toUserName(user)}
          <br />
          <Typography variant="body2" style={{ opacity: 0.5 }}>
            {userEmail}
          </Typography>
        </span>
      </div>
      <IconButton
        onClick={() => {
          onDelete();
        }}
      >
        <Trash2 size={16} />
      </IconButton>
    </AttendeeLi>
  );
};

const AttendeeDialog = ({
  eventDoc,
  open,
  onClose,
  onSubmit
}: {
  eventDoc: Doc<IEvent>;
  open: boolean;
  onClose: () => void;
  onSubmit: (e: IEvent) => any;
}) => {
  const [rsvps, loadingRsvps] = useRsvpsForEvent(eventDoc.id);
  const userIds = useMemo(() => {
    return rsvps ? rsvps.map((r) => r.data.userId) : EMPTY_ARR;
  }, [rsvps]);
  const [rsvpedUsers] = useUserMapByIds(userIds);

  const unrsvp = (userId: Doc<User>['id']) => {
    if (!rsvps) {
      return;
    }

    const rsvpId = rsvps.find((r) => r.data.userId === userId);
    if (rsvpId) {
      unRsvpForEvent(rsvpId.id);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit attendees of "{eventDoc.data.name}"</DialogTitle>
      <DialogContent>
        {!loadingRsvps && (
          <div>
            {rsvpedUsers && rsvps ? (
              <AttendeeWrapper>
                {userIds.map((u) => (
                  <AttendeeRow
                    key={u}
                    user={rsvpedUsers[u]?.data}
                    onDelete={() => {
                      unrsvp(u);
                    }}
                  />
                ))}
              </AttendeeWrapper>
            ) : (
              <p>No RSVPs yet</p>
            )}
          </div>
        )}
        <br />
        <Subheading>Attendee Email List</Subheading>
        <br />
        <Typography variant="body2" component="p" paragraph>
          {userIds && rsvpedUsers
            ? userIds.map((u) => rsvpedUsers[u]?.data.email).join(', ')
            : null}
        </Typography>
        <br />
        <Subheading>Add Attendees</Subheading>
        <br />
        <UserAutocomplete
          onSelect={(user) => {
            if (!user) {
              return;
            }
            rsvpForEvent(eventDoc.id, user.id);
          }}
        />
        <br />
        <br />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EventDialog = ({
  title,
  event,
  open,
  onClose,
  onSubmit
}: {
  title: string;
  event: IEvent;
  open: boolean;
  onClose: () => void;
  onSubmit: (e: IEvent) => any;
}) => {
  const [formEvent, setFormEvent] = useState<IEvent>(event);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(formEvent);
        }}
      >
        <DialogContent>
          <TextField
            label="Name"
            id="name"
            type="text"
            name="name"
            value={formEvent.name}
            onChange={(e) =>
              setFormEvent({ ...formEvent, name: e.target.value })
            }
            placeholder="Event Name"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            label="Description"
            id="description"
            type="textarea"
            multiline
            rows={3}
            name="description"
            placeholder="Event Description"
            value={formEvent.description}
            onChange={(e) =>
              setFormEvent({ ...formEvent, description: e.target.value })
            }
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            label="Event Date / Time"
            name="timestamp"
            id="timestamp"
            type="datetime-local"
            value={formEvent.date.toDate().toISOString().slice(0, 16)}
            onChange={(e) => {
              console.log(e.target.value);
              const newDate = new Date(e.target.value);
              newDate.setMinutes(
                newDate.getMinutes() - newDate.getTimezoneOffset()
              );

              setFormEvent({
                ...formEvent,
                date: fromDate(newDate)
              });
            }}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            label="URL"
            id="url"
            type="text"
            name="url"
            placeholder="https://"
            value={formEvent.url}
            onChange={(e) =>
              setFormEvent({ ...formEvent, url: e.target.value })
            }
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            label="Image Path"
            id="image"
            type="text"
            name="image"
            placeholder="/images/events/...jpg"
            value={formEvent.image}
            onChange={(e) =>
              setFormEvent({ ...formEvent, image: e.target.value })
            }
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <br />
          <br />
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Submit
            </Button>
            <Button style={{ color: 'white' }} onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Form>
    </Dialog>
  );
};

const EventRow = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: ${(p) => p.theme.spacing(4)}px;
  align-items: top;
`;

const EMPTY_EVENT = (): IEvent => ({
  name: '',
  description: '',
  url: '',
  image: '',
  date: now(),
  rsvpCount: 0
});

const IndexPage = () => {
  const [events, loadingEvents] = useEvents();
  const [mode, setMode] = useState<'create' | 'edit'>('create');
  const [currentEventId, setCurrentEventId] = useState<string | null>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [attendeeDialogOpen, setAttendeeDialogOpen] = useState(false);

  const sortedEvents = useMemo(() => {
    if (!events) {
      return [];
    }
    return events.sort(
      (a, b) => b.data.date.toMillis() - a.data.date.toMillis()
    );
  }, [events]);

  const currentEventDoc = events
    ? events.find((e) => e.id === currentEventId)
    : null;

  const resetState = () => {
    setDialogOpen(false);
    setAttendeeDialogOpen(false);
    setCurrentEventId(null);
    setMode('create');
  };

  const handleDialogSubmit = async (event: IEvent) => {
    if (mode === 'create') {
      return createEvent(event).then(resetState);
    }

    if (events && currentEventDoc) {
      return updateEvent(currentEventId as string, event).then(resetState);
    }
  };

  return (
    <Layout>
      <ProductHuntBanner />
      <SEO
        title="Admin"
        siteUrl="https://bloggingfordevs.com"
        description="Admin section, lookout!"
        pathname="admin/events/"
        noIndex
      />
      <Wrapper style={{ maxWidth: '1000px' }}>
        <div
          style={{
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Events
          </Typography>
        </div>
        <Menu />
        <AlignActions>
          <Subheading>All events</Subheading>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            Add an event
          </Button>
        </AlignActions>
        <div>
          {!loadingEvents && !!events && (
            <EventWrapper>
              {sortedEvents.map((e) => (
                <EventRow key={e.id}>
                  <Event {...e} />
                  <div style={{ textAlign: 'right' }}>
                    <ControlButton
                      onClick={() => {
                        setCurrentEventId(e.id);
                        setMode('edit');
                        setDialogOpen(true);
                      }}
                    >
                      <Edit size={16} /> Edit event
                    </ControlButton>
                    <ControlButton
                      onClick={() => {
                        setCurrentEventId(e.id);
                        setAttendeeDialogOpen(true);
                      }}
                    >
                      <UserIcon size={16} /> Modify attendees
                    </ControlButton>
                    <ControlButton onClick={() => removeDoc(e)}>
                      <Trash2 size={16} /> Delete
                    </ControlButton>
                  </div>
                </EventRow>
              ))}
            </EventWrapper>
          )}
        </div>
        {attendeeDialogOpen && (
          <AttendeeDialog
            open={attendeeDialogOpen}
            onClose={resetState}
            eventDoc={currentEventDoc!}
            onSubmit={(e) => {}}
          />
        )}
        {dialogOpen && (
          <EventDialog
            title={mode === 'create' ? 'Create a new event' : 'Edit the event'}
            open={dialogOpen}
            onClose={resetState}
            event={mode === 'create' ? EMPTY_EVENT() : currentEventDoc!.data}
            onSubmit={handleDialogSubmit}
          />
        )}
      </Wrapper>
    </Layout>
  );
};

export default onlyForAdmins(IndexPage);
