import { useEffect } from 'react';
import { useLoadingValue } from '../hooks/usePromise';
import { LoadingValue } from '../services/db';
import { CurrentUser } from '../types/User';

export const checkAdminClaim = async (user: firebase.User) => {
  return user
    .getIdTokenResult()
    .then((result) => !!result.claims.admin)
    .catch(() => false);
};

export const useAdminClaim = (
  user: CurrentUser | null
): LoadingValue<boolean> => {
  const { value, loading, error, setValue } = useLoadingValue<boolean>();
  useEffect(() => {
    if (!user) {
      if (value) {
        return setValue(false);
      }
      return;
    }
    checkAdminClaim(user.authUser).then(setValue);
  }, [user]);
  return [value, loading, error];
};
