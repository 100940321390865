import { Link } from 'gatsby';
import React from 'react';
import styled from '../../styled';
import { COLORS } from '../../themes/colors';

const ACTIVE_STYLE = {
  borderBottom: `3px solid ${COLORS.primary.main}`
};

const MenuWrapper = styled('ul')`
  list-style-type: none;
  text-align: center;

  li {
    display: inline-block;
    margin-right: ${(p) => p.theme.spacing(2)}px;
  }
`;

export const Menu = () => {
  return (
    <MenuWrapper>
      <li>
        <Link to="/admin/" activeStyle={ACTIVE_STYLE}>
          Admin Home
        </Link>
      </li>
      <li>
        <Link to="/admin/events/" activeStyle={ACTIVE_STYLE}>
          Events
        </Link>
      </li>
      <li>
        <Link to="/admin/users/" activeStyle={ACTIVE_STYLE}>
          Users
        </Link>
      </li>
      <li>
        <Link to="/admin/comments/" activeStyle={ACTIVE_STYLE}>
          Comments
        </Link>
      </li>
      <li>
        <Link to="/admin/rankings/submissions/" activeStyle={ACTIVE_STYLE}>
          Rankings Submissions
        </Link>
      </li>
      <li>
        <Link to="/admin/rankings/blogs/" activeStyle={ACTIVE_STYLE}>
          Rankings Blogs
        </Link>
      </li>
    </MenuWrapper>
  );
};
