import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { Doc } from '../services/document';
import { toUserName, useUsersPublic } from '../services/usersPublic';
import { User } from '../types/User';

export const UserAutocomplete = ({
  onSelect
}: {
  onSelect: (user: Doc<User> | null) => any;
}) => {
  const [users, loadingUsers] = useUsersPublic();
  const [selectedUser, setSelectedUser] = useState<Doc<User> | null>(null);

  if (!users || loadingUsers) {
    return null;
  }

  return (
    <Autocomplete
      id="user-autocomplete"
      options={users}
      value={selectedUser}
      onChange={(e: any, user: Doc<User> | null) => {
        e.preventDefault();
        if (user) {
          onSelect(user);
          setSelectedUser(null);
        }
      }}
      getOptionLabel={(user) => `${toUserName(user.data)} (${user.data.email})`}
      renderInput={(params) => (
        <TextField {...params} label="User" variant="filled" fullWidth />
      )}
    />
  );
};
